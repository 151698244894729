<template>
    <div :class="pageClass">
        <h1 :class="titleClass" v-if="title">{{ title }}</h1>
        <h2 :class="subtitleClass" class="my-3 my-md-6" v-if="subtitle">{{ subtitle }}</h2>
        <div :class="pageSlotClass">
            <slot />
        </div>
    </div>
</template>
  
<script>
export default {
    name: "Page",
    props: { "title": String, "subtitle": String, "home": Boolean },
    computed: {
        pageClass() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                return "page-desktop";
                case "md":
                case "sm":
                    return "page-tablet";
                case "xs":
                    return "page-phone";
            }
        },
        pageSlotClass() {
            if (this.home) {
                return "";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                return "page-slot-desktop";
                case "md":
                case "sm":
                    return "page-slot-tablet";
                case "xs":
                    return "page-slot-phone";
            }
        },
        titleClass() {
            if (this.home) {
                switch (this.$vuetify.breakpoint.name) {
                    case "xl":
                    case "lg":
                    return "montserrat-extra-bold-purple-pizzazz-48px title-desktop";
                    case "md":
                    case "sm":
                        return "montserrat-extra-bold-purple-pizzazz-24px title-tablet";
                    case "xs":
                        return "montserrat-extra-bold-purple-pizzazz-24px title-phone";
                }
            } else {
                switch (this.$vuetify.breakpoint.name) {
                    case "xl":
                    case "lg":
                    return "title-white montserrat-extra-bold-purple-pizzazz-48px title-desktop";
                    case "md":
                        return "title-white montserrat-extra-bold-purple-pizzazz-24px title-tablet";
                    case "sm":
                    case "xs":
                        return "title-white montserrat-extra-bold-purple-pizzazz-24px title-phone";
                }
            }
        },
        subtitleClass() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                    return "material-themeheadlinesmall subtitle";
                case "md":
                return "material-themebodylarge subtitle";
                case "sm":
                case "xs":
                    return "material-themebodylarge subtitle";
            }
        },
    },
};
</script>

<style>
.page-desktop {
  margin: 80px 120px;
}

.page-tablet {
  margin: 40px;
}

.page-phone {
  margin: 20px;
}

.page-slot-desktop {
  margin-top: 80px;
  margin-bottom: 80px;
}

.page-slot-tablet {
  margin-top: 40px;
  margin-bottom: 40px;
}

.page-slot-phone {
  margin-top: 28px;
  margin-bottom: 28px;
}

.title-desktop {
  width: 100%;
  text-align: center;
  margin-top: 80px;
}

.title-tablet {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.title-phone {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.title-white {
    color: white !important;
}

.subtitle {
  color: var(--iron);
  text-align: center;
  line-height: normal;
}
</style>
  