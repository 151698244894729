<template>
  <page class="screen" title="Frequently Asked Questions">
    <f-a-q-section headers :faqs="faqs" />
  </page>
</template>

<script>
import FAQSection from "./FAQSection.vue"
import Page from "./Page"

export default {
  name: "FAQ",
  components: { FAQSection, Page },
  props: [ "faqs" ],
};
</script>

<style scoped>

</style>
