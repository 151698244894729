<template>
  <div>
    <page class="screen" :title="`${name} Network`">
      <div>
        <v-container no-gutters class="mb-3 mb-md-6 pb-3 pb-md-6">
          <v-row class="px-4 px-sm-0" justify="center">
            <v-col class="mx-4" :class="$vuetify.breakpoint.xs ? 'network-col-mobile' : 'network-col-desktop'"
              align="center" :cols="$vuetify.breakpoint.smAndUp ? undefined : 12">
              <div class="network-logo my-md-6">
                <img class="network-logo" :src="logo" :alt="`${name} icon`" />
              </div>
              <div class="network-name montserrat-bold-iron-20px">{{ name }}</div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? undefined : 12" class="pr-sm-6">
              <div class="my-md-6 text material-themebodylarge">{{ description }}</div>
              <div class="my-6 text highlight material-themebodylarge">{{ instructions }}</div>
              <div>
                <a :href="primary.link" target="_blank" v-if="primary">
                  <v-btn rounded primary color="var(--purple-pizzazz)" style="color: white;"
                    class="montserrat-medium-white-14px mr-10 mb-6">{{ primary.label
                    }}</v-btn>
                </a>
                <a :href="secondary.link" target="_blank" v-if="secondary">
                  <v-btn rounded outlined color="var(--purple-pizzazz)"
                    style="border-color: var(--m-3-sys-light-outline, #79757F);" class="montserrat-medium-white-14px mb-6">{{
                      secondary.label }}</v-btn>
                </a>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-container no-gutters class="mb-3 mb-md-6 pb-3 pb-md-6"
          :class="$vuetify.breakpoint.mdAndUp ? 'chart-mobile' : 'chart-desktop'">
          <div>
            <div class="mb-5">
              <span class="last-price dot-price material-themetitlemedium mr-10">{{ lastPrice }}</span>
              <span :class="lastPriceChangeClass" class="material-themetitlemedium">{{ lastPriceChange }}</span>
              <div></div>
            </div>
            <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
              :height="height" />
          </div>
        </v-container>
      </div>
    </page>
    <page home title="FAQ" v-if="faqs[0].questions.length > 0">
      <f-a-q-section :faqs="faqs" />

      <div style="width: fit-content; margin: 0 auto;">
        <router-link to="/faq">
          <v-btn rounded primary color="var(--purple-pizzazz)" style="color: white;"
            class="montserrat-medium-white-14px">Learn more</v-btn>
        </router-link>
      </div>
    </page>
  </div>
</template>

<script>
import FAQSection from "./FAQSection.vue"
import Page from "./Page"

import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

const lineShadownPlugin = {
  id: 'line_shadow',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;

    let _stroke = ctx.stroke;
    ctx.stroke = function () {
      ctx.save();
      ctx.shadowColor = '#E500CF';
      ctx.shadowBlur = 100;
      _stroke.apply(this, arguments);
      ctx.restore();
    }
  },
}

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  lineShadownPlugin
);

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-20%, -50%)';
    tooltipEl.style.transition = 'all .1s ease';
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const day = tooltip.title[0] || "";
    const price = tooltip.body.map(b => b.lines)[0] || "";

    const priceDiv = document.createElement('div');

    const priceContainerDiv = document.createElement('div');
    priceContainerDiv.classList.add('dot-price', 'material-themetitlemedium');
    priceDiv.appendChild(priceContainerDiv);

    priceContainerDiv.appendChild(document.createTextNode("$" + price));
    priceDiv.classList.add('tooltip-price');
    priceDiv.style.marginBottom = '140px';

    const dayDiv = document.createElement('div');
    dayDiv.appendChild(document.createTextNode(day));
    dayDiv.classList.add('tooltip-day', 'material-themebodysmall');

    // Remove old children
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }

    // Add new children
    tooltipEl.appendChild(priceDiv);
    tooltipEl.appendChild(dayDiv);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export default {
  name: "Network",
  components: { FAQSection, LineChartGenerator, Page },
  props: ["name", "logo", "description", "instructions", "primary", "secondary", "coin", "faqs"],
  data() {
    const img = new Image(110, 160);
    img.src = require("@/assets/img/network/pin.png");

    return {
      lastPrice: undefined,
      lastPriceChange: undefined,
      lastPriceChangeClass: "",
      chartData: {
        datasets: [{
          data: [],
        }],
        labels: [],
      },
      chartId: 'line-chart',
      height: this.$vuetify.breakpoint.mobile ? 330 : 380,
      chartOptions: {
        scales: {
          x: {
            grid: {
              display: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },

          },
          y: {
            grid: {
              color: '#1F1F39',
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          }
        },
        elements: {
          line: {
            borderColor: '#E500CF',
            borderWidth: 4,
            cubicInterpolationMode: 'monotone',
            borderCapStyle: 'round',
          },
          point: {
            backgroundColor: '#E500CF',
            radius: 0,
            hitRadius: 4,
            hoverRadius: 1,
            pointStyle: img,
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
          axis: 'x',
        },
        transitions: {
          active: {
            animation: {
              duration: 0,
            }
          }
        },
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 1,
            to: 0,
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: 'average',
            external: externalTooltipHandler
          }
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  created() {
    this.fetchData(this.coin);
  },
  watch: {
    coin: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchData(newVal);
      }
    }
  },
  methods: {
    formatPrice(price, base = 10000) {
      return Math.round((price + Number.EPSILON) * base) / base;
    },
    formatDate(millis) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(millis).toLocaleDateString(undefined, options);
    },
    fetchData(coin) {
      fetch(`https://api.coingecko.com/api/v3/coins/${coin}/market_chart?vs_currency=usd&days=30&interval=daily`)
        .then(res => res.json())
        .then(data => {
          let labels = data.prices.map(dayPrice => this.formatDate(dayPrice[0]));
          let values = data.prices.map(dayPrice => this.formatPrice(dayPrice[1]));
          return {
            labels: labels,
            values: values,
          };
        })
        .then(data => {
          let minPrice = Math.min(...(data.values));
          let maxPrice = Math.max(...(data.values));

          this.chartOptions.scales.x.suggestedMin = 0;
          this.chartOptions.scales.x.suggestedMax = 100;

          this.chartOptions.scales.y.suggestedMin = minPrice * 0.95;
          this.chartOptions.scales.y.suggestedMax = maxPrice * 1.05;

          this.chartData = {
            datasets: [{
              data: data.values,
            }],
            labels: data.labels,
          };

          this.lastPrice = "$" + data.values[data.values.length - 1];
          let penLastPrice = this.formatPrice(data.values[data.values.length - 2] + Number.EPSILON);
          let lastDayChange = (data.values[data.values.length - 1] - penLastPrice) / penLastPrice * 100;
          this.lastPriceChange = this.formatPrice(lastDayChange, 100).toFixed(2) + "%";

          if (lastDayChange >= 0) {
            this.lastPriceChangeClass = 'last-price-updated-positive';
          } else {
            this.lastPriceChangeClass = 'last-price-updated-negative';
          }
        },
          error => { },
        );
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  background-color: var(--vulcan);
  border-radius: 24px;
  border: 1px solid var(--thunder);
}

.network-col-desktop {
  max-width: 200px
}

.network-col-mobile {
  max-width: 100%;
}

.chart-desktop {
  width: 100%;
  padding: 24px;
}

.chart-mobile {
  width: 100%;
  padding: 12px;
}

.text {
  color: var(--iron);
  line-height: 34px;
  text-align: justify;
}

.highlight {
  padding-left: 24px;
  border-width: 0px;
  border-left-width: 4px;
  border-style: solid;
  border-color: var(--purple-pizzazz);
}

.v-btn {
  text-transform: none;
  width: 150px;
}

.last-price {
  color: #D8D8D8;
}

.last-price-updated-positive {
  color: #00FF85;
  background-image: url('~@/assets/img/network/arrow-up.png');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: right 0px top 50%;
  padding-right: 30px;
}

.last-price-updated-negative {
  color: red;
  background-image: url('~@/assets/img/network/arrow-down.png');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: right 0px top 50%;
  padding-right: 25px;
}
</style>

<style>
.tooltip-price {
  padding: 4px 12px;
  flex-shrink: 0;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(51, 47, 103, 0.44) 2.1%, rgba(61, 60, 125, 0.40) 98.26%);
  box-shadow: 0px 12px 14px 0px rgba(32, 30, 66, 0.51);
  backdrop-filter: blur(9.277350425720215px);
  color: #CECEEA;
  text-align: center;
  width: fit-content;
}

.tooltip-day {
  padding: 4px 12px;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(51, 47, 103, 0.44) 2.1%, rgba(61, 60, 125, 0.40) 98.26%);
  box-shadow: 0px 12px 14px 0px rgba(32, 30, 66, 0.51);
  backdrop-filter: blur(9.277350425720215px);
  color: #CECEEA;
  text-align: center;
  width: fit-content;
}

.dot-price {
  background-image: url('~@/assets/img/network/dot.png');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 0px 50%;
  padding-left: 20px;
}
</style>
