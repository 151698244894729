<template>
    <div>
        <v-expansion-panels v-for="(section, index) in faqs" class="faq-sections my-10" :key="index">
            <div class="faq-section-label material-themeheadlinesmall" v-if="headers">{{ section.label }}</div>
            <v-expansion-panel v-for="(question, index) in section.questions" class="faq-section" :key="index">
                <v-expansion-panel-header class="faq-section-question material-themetitlemedium" color="var(--cinder)">
                    <template v-slot:actions>
                    <v-icon color="var(--purple-pizzazz)">$expand</v-icon>
                    </template>
                    {{ question.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="faq-section-answer material-themetitlemedium" color="var(--cinder)">
                    <div v-html=question.description></div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>

export default {
    name: "FAQ",
    components: { },
    props: { faqs: [], headers: Boolean },
};
</script>

<style scoped>
.faq-section-label {
color: var(--purple-pizzazz);
font-style: normal;
font-weight: 700;
line-height: 34px;
text-transform: uppercase;
width: 100%;
text-align: start;
margin-bottom: 24px;
}

.faq-section {
border: 1px solid var(--thunder);
margin-bottom: 24px;
border-radius: 24px !important;
overflow: hidden;
}

.faq-section-answer {
color: var(--iron);
font-style: normal;
font-weight: 400;
line-height: 24px;
}

.faq-section-question {
color: var(--iron);
font-style: normal;
font-weight: 700;
line-height: 24px;
}

.v-expansion-panels {
background-color: var(--cinder);
}

.v-expansion-panels>>>.v-expansion-panel {
background-color: var(--cinder);
}

.v-expansion-panel--active>>>.v-expansion-panel-header {
background-color: var(--vulcan) !important;
color: var(--purple-pizzazz);
}

.v-expansion-panel--active>>>.v-expansion-panel-content {
background-color: var(--vulcan) !important;
}

.v-expansion-panel-content>>>a {
color: var(--purple-pizzazz);
}
</style>
