<template>
    <v-app>
        <v-app-bar app dark hide-on-scroll flat color="#0B0C18" height="104px" class="ps-app-bar">
            <div class="d-flex align-center py-16">
                <router-link to="/">
                    <v-img alt="PartnerStaking logo" class="shrink" contain height="40px"
                        :src='require("@/assets/img/logo.png")' :lazy-src='require("@/assets/img/logo-lazy.png")' />
                </router-link>
            </div>

            <v-spacer></v-spacer>

            <div v-for="(category, index) in navigation" :key="index" class="mr-10">
                <v-menu open-on-hover bottom offset-y class="menu-drop-down">
                    <template v-slot:activator="{ on, attrs }">
                        <div class="menu-category montserrat-bold-iron-16px" v-bind="attrs" v-on="on">
                            {{ category.label }} <img class="menu-category-arrow"
                                :src="require('@/assets/img/arrow-drop-down.png')" alt="Expand" />
                        </div>
                    </template>

                    <img class="menu-drop-down-before" :src="require('@/assets/img/drop-down-before.png')" />
                    <v-list class="menu-drop-down">
                        <v-list-item v-for="(link, index) in category.routes" :key="index">
                            <v-list-item-title>
                              <v-tooltip bottom v-if="link.enabled === false">
                                <template v-slot:activator="{ on, attrs }">
                                  <div
                                    v-bind="attrs"
                                    v-on="on"
                                    class="menu-drop-down-item material-themebodylarge">{{ link.label }}</div>
                                </template>
                                <span>Coming soon</span>
                              </v-tooltip>
                              <router-link :to="link.route" class="menu-drop-down-item material-themebodylarge"
                                  v-else-if="link.route" active-class="menu-drop-down-item-active">
                                  {{ link.label }}
                              </router-link>
                              <a :href="link.external" class="menu-drop-down-item material-themebodylarge" target="_blank"
                                  v-else>{{ link.label }}</a>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <div style="width: 40px;"></div>

        </v-app-bar>

        <v-main class="ps-app-bar">
            <RouterView />
            <footer-view />
        </v-main>
    </v-app>
</template>
  
<script>
import { RouterView } from 'vue-router';
import FooterView from './FooterView.vue'
import { navigation } from '../data.js'

export default {
    name: 'AppDesktopLayout',
    components: { RouterView, FooterView },
    data() {
        return {
            navigation: navigation,
        };
    },
};
</script>
  
<style scoped>
.v-application {
    background-color: #0B0C18 !important;
}

.ps-app-bar {
  width: 1440px;
  margin: 0 auto;
}

.menu-category {
  color: var(--iron);
  font-weight: 700;
  line-height: normal;
  width: 120px;
  cursor: pointer;
}

.menu-category[aria-expanded="true"] {
  color: var(--purple-pizzazz) !important;
}

.menu-category-arrow {
  margin-bottom: -6px;
  height: 24px;
  width: 24px;
}

.menu-category[aria-expanded="true"] > img.menu-category-arrow {
  transform: rotate(180deg);
}

.menu-drop-down {
  margin-top: -8px;
  background-color: var(--steel-gray-2) !important;
  border-radius: 0px 0px 12px 12px !important;
  padding: 10px;
}

.menu-drop-down-item {
  color: var(--iron) !important;
  font-weight: 400;
  line-height: normal;
  width: 120px;
  text-decoration: none !important;
}

.menu-drop-down-item:hover {
  color: var(--purple-pizzazz) !important;
}

.menu-drop-down-item > div > .router-link-active {
  color: var(--purple-pizzazz) !important;
}

.menu-drop-down-item-active {
  color: var(--purple-pizzazz) !important;
}

.menu-drop-down-before {
  height: 15px;
  width: 17px;
  margin-top: 5px;
  margin-left: 25px;
}
</style>