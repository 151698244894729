<template>
  <div class="network-frame">
    <div class="network-info">
      <div class="network-logo">
        <img
          class="network-logo"
          :src="logo"
          :alt="`${name} icon`"
        />
      </div>
      <div class="network-name montserrat-bold-iron-20px">{{ name }}</div>
      <p class="network-stats material-themebodylarge">{{ stats }}</p>
      <v-tooltip bottom v-if="enabled === false">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="network-learn-more">
            <div class="network-learn-more-label montserrat-bold-purple-pizzazz-16px">Learn more</div>
            <img class="network-learn-more-icon" :src="require('@/assets/img/arrow-right-dazzle.png')" alt="Go" />
          </div>
        </template>
        <span>Coming soon</span>
      </v-tooltip>
      <router-link :to="route"  v-else>
        <div class="network-learn-more">
          <div class="network-learn-more-label montserrat-bold-purple-pizzazz-16px">Learn more</div>
          <img class="network-learn-more-icon" :src="require('@/assets/img/arrow-right-dazzle.png')" alt="Go" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NetworkFrame",
  props: ["name", "logo", "stats", "route", "enabled"],
};
</script>

<style>
.network-frame {
  align-items: center;
  border: 1px solid;
  border-color: var(--thunder);
  border-radius: 24px;
  display: flex;
  height: 320px;
  justify-content: center;
  position: relative;
  width: 320px;
  margin: 0;
  max-width: 100%;
}

.network-info {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 260px;
  justify-content: center;
  margin-left: -1px;
  overflow: hidden;
  position: relative;
  width: 260px;
  max-width: 100%;
}

.network-name {
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
}

.network-stats {
  color: var(--iron);
  font-weight: 400;
  line-height: 20px;
  margin-left: -14px;
  margin-right: -14px;
  position: relative;
  text-align: center;
  width: 288px;
  max-width: 100%;
}

.network-logo {
  align-items: center;
  display: flex;
  height: 120px;
  justify-content: center;
  position: relative;
  width: 120px;
}

.network-icon {
  height: 73.38px;
  position: relative;
  width: 80px;
}

.network-logo .network-icon {
  height: 73.38px;
}

.network-learn-more {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.network-learn-more-label {
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.network-learn-more-icon {
  height: 20px;
  position: relative;
  width: 20px;
}
</style>
