<template>
    <v-app>
        <v-app-bar app flat dark hide-on-scroll color="#0B0C18" height="52px" class="px-xs-6 px-sm-8">
            <router-link to="/">
                <v-img alt="PartnerStaking logo" contain max-height="28px" max-width="148px"
                    :src='require("@/assets/img/logo.png")'  :lazy-src='require("@/assets/img/logo-lazy.png")' />
            </router-link>
            <v-spacer />
            <v-app-bar-nav-icon color="var(--purple-pizzazz)" @click.stop="drawer = !drawer"><v-icon
                    v-if="drawer">mdi-close</v-icon></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app dark right temporary floating clipped hide-overlay class="nav-drawer"
            :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }">
            <v-list dense rounded subheader>
                <template v-for="category in navigation">
                    <v-subheader class="material-themebodymedium">{{ category.label }}</v-subheader>
                    <v-list-item v-for="link in category.routes" :key="link.label">
                        <v-list-item-icon>
                            <v-img class="menu-item-icon" :src="link.icon" />
                        </v-list-item-icon>
                        <v-list-item-title>
                            <v-tooltip bottom v-if="link.enabled === false">
                                <template v-slot:activator="{ on, attrs }">
                                  <div
                                    v-bind="attrs"
                                    v-on="on"
                                    class="menu-item material-themebodylarge">{{ link.label }}</div>
                                </template>
                                <span>Coming soon</span>
                              </v-tooltip>
                            <router-link :to="link.route" class="menu-item material-themebodylarge" v-else-if="link.route"
                                active-class="menu-item-active">
                                {{ link.label }}
                            </router-link>
                            <a :href="link.external" class="menu-item material-themebodylarge" target="_blank" v-else>{{
                                link.label }}</a>
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-overlay :value="drawer" z-index="3" color="#000000" opacity="0.72" />
            <router-view />
            <footer-view />
        </v-main>
    </v-app>
</template>
  
<script>
import { RouterView } from 'vue-router';
import FooterView from './FooterView.vue'
import { navigation } from '../data.js'

export default {
    name: 'AppMobileLayout',
    components: { RouterView, FooterView },
    data() {
        return {
            navigation: navigation,
            drawer: false,
        };
    },
};
</script>
  
<style scoped>
.v-application {
    background-color: var(--cinder) !important;
}

.nav-drawer {
    height: auto !important;
    border-radius: 0px 0px 16px 16px;
    background: linear-gradient(0deg, rgba(98, 72, 196, 0.05) 0%, rgba(98, 72, 196, 0.05) 100%), #21213A;
}

.v-subheader {
    color: var(--iron);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
}

.menu-item {
    color: var(--iron) !important;
    font-weight: 400;
    line-height: normal;
    text-decoration: none !important;
    width: 100%;
    display: block;
}

.menu-item:hover {
    color: var(--purple-pizzazz) !important;
}

.menu-item-active {
    font-weight: 700;
}

.v-list-item:has(> div > a.menu-item-active) {
    background: var(--purple-pizzazz);
}

.menu-item-icon {
    height: 24px;
    width: 24px;
}
</style>