<template>
  <app-desktop-layout v-if="!$vuetify.breakpoint.mobile" />
  <app-mobile-layout v-else />
</template>

<script>
import { RouterView } from 'vue-router';
import AppDesktopLayout from './components/AppDesktopLayout.vue'
import AppMobileLayout from './components/AppMobileLayout.vue'

export default {
    name: 'App',
    components: { RouterView, AppDesktopLayout, AppMobileLayout },
};
</script>

<style>
.v-application {
  background-color: #0B0C18 !important;
}

.container {
  overflow: hidden;
}
</style>