<template>
  <div class="outcome-frame">
    <div class="outcome-info">
      <div class="outcome-icon-container">
        <img
          class="outcome-icon"
          :src="icon"
          :alt="`${title} icon`"
        />
      </div>
      <div class="outcome-title montserrat-bold-purple-pizzazz-20px">{{ title }}</div>
      <p class="outcome-description material-themebodylarge">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StakingOutcome",
  props: ["title", "description", "icon"],
};
</script>

<style>
.outcome-frame {
  align-items: center;
  border: 1px solid;
  background-color: var(--vulcan);
  border-color: var(--thunder);
  border-radius: 24px;
  display: flex;
  height: 320px;
  width: 320px;
  justify-content: center;
  position: relative;
  margin: 0;
  max-width: 100%;
}

.outcome-info {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 260px;
  justify-content: center;
  margin-left: -1px;
  overflow: hidden;
  position: relative;
  width: 260px;
  max-width: 100%;
}

.outcome-title {
  line-height: normal;
  text-align: center;
  max-width: 100%;
}

.outcome-description {
  color: var(--iron);
  line-height: normal;
  text-align: center;
  max-width: 100%;
}

.outcome-icon-container {
  align-items: center;
  display: flex;
  height: 120px;
  justify-content: center;
  position: relative;
  width: 120px;
  max-width: 100%;
}

.outcome-icon {
  height: 48px;
  position: relative;
  width: 48px;
}

.outcome-icon-container .outcome-icon {
  height: 48px;
}

.outcome-learn-more {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.outcome-learn-more-label {
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.outcome-learn-more-icon {
  height: 20px;
  position: relative;
  width: 20px;
}
</style>
