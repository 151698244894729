import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Home";
import FAQ from "./components/FAQ";
import Network from "./components/Network";
import Policy from "./components/Policy";
import TC from "./components/TC";
import {
  CelestiaData,
  EthereumData,
  faqs,
  homeData,
  InjectiveData,
  MultiversXData,
  privacyPolicy,
  RealioData,
  termsAndConditions,
  ZPayData
} from "./data";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/stake-egld",
      component: Network,
      props: {
        ...MultiversXData
      },
    },
    {
      path: "/stake-zpay",
      component: Network,
      props: {
        ...ZPayData
      },
    },
    // {
    //   path: "/stake-eth",
    //   component: Network,
    //   props: {
    //     ...EthereumData
    //   },
    // },
    {
      path: "/stake-inj",
      component: Network,
      props: {
        ...InjectiveData
      },
    },
    {
      path: "/stake-tia",
      component: Network,
      props: {
        ...CelestiaData
      },
    },
    {
      path: "/stake-rio",
      component: Network,
      props: {
        ...RealioData
      },
    },
    {
      path: "/faq",
      component: FAQ,
      props: {
        faqs: faqs,
      },
    },
    {
      path: "/policy",
      component: Policy,
      props: { data: privacyPolicy },
    },
    {
      path: "/terms",
      component: TC,
      props: { data: termsAndConditions },
    },
    {
      path: "*",
      component: Home,
      props: { ...homeData },
    },
  ],
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
        return {
          selector: to.hash,
          offset: {
            x: 0, y: 85
          }
        }
    } else {
        return { x: 0, y: 0 }
    }
},
});
