<template>
    <v-container class="footer" fluid>
        <v-row no-gutters style="width: 100%;" justify="center" align="start">
            <v-col cols="12" md="3" class="mt-3">
                <router-link to="/">
                    <v-img alt="PartnerStaking logo" contain max-height="40px" max-width="212px" class="mb-7" style="margin: 0 auto;"
                        :src="brand.logo" :lazy-src="brand.lazylogo" />
                </router-link>
            </v-col>
            <v-col cols="12" md="3" v-for="(column, index) in footer" :key="index">
                <v-list dense rounded class="footer-column">
                    <v-list-item-title class="footer-column-header montserrat-bold-iron-20px ml-4">{{ column.label
                    }}</v-list-item-title>
                    <v-list-item v-for="(link, index) in column.routes" :key="index">
                        <v-list-item-icon v-if="link.icon && link.external">
                            <v-img class="footer-menu-item-icon" :src="link.icon" />
                        </v-list-item-icon>
                        <v-list-item-title>
                            <v-tooltip bottom v-if="link.enabled === false">
                                <template v-slot:activator="{ on, attrs }">
                                  <div
                                    v-bind="attrs"
                                    v-on="on"
                                    class="footer-menu-item material-themebodylarge">{{ link.label }}</div>
                                </template>
                                <span>Coming soon</span>
                              </v-tooltip>
                            <router-link :to="link.route" class="footer-menu-item material-themebodylarge" v-else-if="link.route"
                                active-class="footer-menu-item-active">
                                {{ link.label }}
                            </router-link>
                            <a :href="link.external" class="footer-menu-item material-themebodylarge" target="_blank"
                                v-else>{{
                                    link.label }}</a>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-row no-gutters style="width: 100%;" justify="center" align="start">
            <v-col sm="6" md="12">
                <div class="montserrat-bold-iron-16px" :class="$vuetify.breakpoint.mobile ? 'footer-copywrite-mobile' : 'footer-copywrite-desktop'">{{ brand.copywrite }}</div>
            </v-col>
        </v-row>
        <div :class="$vuetify.breakpoint.mobile ? 'footer-splash-container-mobile' : 'footer-splash-container-desktop'">
            <div :class="$vuetify.breakpoint.mobile ? 'footer-splash-mobile' : 'footer-splash-desktop'" :style="{
                'margin-left': $vuetify.breakpoint.smAndDown ? '-350px' : 'auto'
            }"></div>
        </div>
    </v-container>
</template>
  
<script>
import { brand, footer } from '../data.js'

export default {
    name: "FooterView",
    components: {},
    data() {
        return {
            brand: brand,
            footer: footer,
        };
    },
};
</script>
  
<style scoped>
.footer {
    border: 0 !important;
    border-radius: 0 !important;
}

.footer-column {
    background-color: #0B0C18 !important;
    width: fit-content;
    margin: 0 auto;
}

.footer-column-header {
    color: var(--iron) !important;
    letter-spacing: 12px;
    line-height: 52px;
    text-transform: uppercase;
    width: fit-content;
    align-items: center;
}

.footer-menu-item {
    color: var(--iron) !important;
    font-weight: 400;
    line-height: normal;
    width: 120px;
    text-decoration: none !important;
}

.footer-menu-item:hover {
    color: var(--purple-pizzazz) !important;
}

.footer-menu-item-active {
    font-weight: 700;
    color: var(--purple-pizzazz) !important;
}

.footer-menu-item-icon {
    height: 24px;
    width: 24px;
}

.footer-copywrite-desktop {
    margin-top: 120px;
}

.footer-copywrite-mobile {
    margin-top: 80px;
    text-align: center;
}

.footer-splash-container-desktop {
    height: 80px;
    width: 100%;
}

.footer-splash-container-mobile {
    height: 60px;
    width: 100%;
}

.footer-splash-desktop {
    width: 948px;
    height: 974px;
    margin: auto;
    flex-shrink: 0;
    border-radius: 974px;
    background: linear-gradient(271deg, #7000FF 26.72%, #B80847 73.69%);
    filter: blur(170px);
}

.footer-splash-mobile {
    width: 995px;
    height: 1339.142px;
    flex-shrink: 0;
    border-radius: 1339.142px;
    background: linear-gradient(271deg, #7000FF 26.72%, #B80847 73.69%);
    filter: blur(170px);
}
</style>
  