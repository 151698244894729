<template>
  <v-container no-gutters fluid class="banner-container">
    <v-row justify="center">
      <a href="https://play.google.com/store/apps/details?id=dev.peppark.partnerstaking.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
        <v-col class="banner ma-2">
          <img class="banner-image" :src="require('@/assets/img/home/app/play-store.png')" alt="Get it on Google Play" />
          <div class="banner-text">
            <div class="banner-line montserrat-normal-iron-14px">Download on</div>
            <div class="banner-line montserrat-bold-iron-16px">Google Play</div>
          </div>
        </v-col>
      </a>
      <a href="https://apps.apple.com/us/app/partner-staking/id1558363331" target="_blank">
        <v-col class="banner ma-2">
          <img class="banner-image" :src="require('@/assets/img/home/app/app-store.png')" alt="Download on the App Store" />
          <div class="banner-text">
            <div class="banner-line montserrat-normal-iron-14px">Download on</div>
            <div class="banner-line montserrat-bold-iron-16px">App Store</div>
          </div>
        </v-col>
      </a>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppInstallBanner",
  props: [],
};
</script>

<style scoped>
.banner-container {
  border: 0;
}

.banner {
  display: flex;
  max-width: 214px;
  height: 88px;
  padding: 20px 28px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
  border: 1px solid #E500CF;
  background: #000;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  cursor: pointer;
}

.banner-image {
  height: 38px;
  margin-left: -2px;
  position: relative;
  width: 35px;
}

.banner-text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-right: -4px;
  position: relative;
}

.banner-line {
  letter-spacing: 0.2px;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
</style>
