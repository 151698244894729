<template>
  <div class="screen">
    <div class="banner-container">
      <div class="banner" :class="bannerClass" :style="{ 'background-image': 'url(' + banner + ')' }">
        <h1 class="banner-title" :class="bannerHeadlineTitle">{{ headline.title }}</h1>
        <h2 class="banner-subtitle" :class="bannerHeadlineSubtitle">{{ headline.subtitle }}</h2>
      </div>
    </div>

    <page home :title="section1.title" :subtitle="section1.subtitle">
      <v-container no-gutters fluid class="section-container">
          <v-row class="full-row" justify="center">
            <v-col class="px-0 px-sm-3 py-3" v-for="(network, index) in section1.networks" cols="12" sm="6" lg="4" :key="index">
              <network-frame :class="networkClass(index)"
                :name="network.name"
                :logo="network.logo"
                :stats="network.stats"
                :route="network.route"
                :enabled="network.enabled"
                />
            </v-col>
          </v-row>
        </v-container>
    </page>

      <div class="benefits-container mb-5 mb-sm-10 mb-lg-16">
        <v-container no-gutters class="section-container" :class="benefits">
          <v-row class="full-row" justify="space-around">
            <v-col v-for="(benefit, index) in section2.benefits" class="benefit-container" cols="6" sm="3" :key="index">
              <div class="mx-6">
                <img class="benefit-icon" :src="benefit.icon" :alt="benefit.label" />
                <div class="benefit-label material-themetitlemedium">{{ benefit.label }}</div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <page home :title="section3.title" id="about-us">
        <v-container no-gutters class="section-container">
          <v-row justify="center">
            <v-col cols="12" md="7">
              <div v-for="(paragraph, index) in section3.paragraphs" class="paragraph material-themebodylarge my-3 my-md-6" :key="index">{{ paragraph }}</div>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <img :src="section3.image" contain style="width: 100%;" />
            </v-col>
          </v-row>
        </v-container>
      </page>

      <page home :title="section4.title" :subtitle="section4.subtitle">
        <v-container no-gutters class="section-container">
          <v-row class="full-row" justify="center">
            <v-col class="px-0 px-sm-3 py-3" v-for="(outcome, index) in section4.outcomes" style="max-width: fit-content;" :key="index">
              <staking-outcome
                :title="outcome.title"
                :description="outcome.description"
                :icon="outcome.icon"
                />
            </v-col>
          </v-row>
        </v-container>
      </page>

      <page home id="mobile-apps">
        <v-container no-gutters class="section-container" :class="section5Class">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="7" lg="6">
              <h1 :class="title" class="section5-title">{{ section5.title }}</h1>
              <h2 :class="subtitle" class="section5-headline mt-3 mt-md-6 mb-9">{{ section5.subtitle }}</h2>
              <div v-for="(paragraph, index) in section5.paragraphs" class="paragraph section5-paragraph material-themebodylarge mb-8" :key="index">{{ paragraph }}</div>
              <app-install-banner v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.lgAndUp" />
            </v-col>
            <v-col cols="12" sm="5" lg="6" style="width: 100%; text-align: center;">
              <img :src="section5.image" contain />
            </v-col>
          </v-row>
        </v-container>
        <app-install-banner v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.md" />
      </page>
  </div>
</template>

<script>
import AppInstallBanner from "./AppInstallBanner";
import NetworkFrame from "./NetworkFrame"
import Page from "./Page"
import StakingOutcome from "./StakingOutcome"

export default {
  name: "Home",
  components: {
    AppInstallBanner,
    NetworkFrame,
    Page,
    StakingOutcome
},
  props: [
    "banner",
    "headline",
    "section1",
    "section2",
    "section3",
    "section4",
    "section5",
  ],
  computed: {
    bannerClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "banner-desktop";
        case "md":
        case "sm":
        case "xs":
          return "banner-mobile";
      }
    },
    bannerHeadlineTitle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          if (this.$vuetify.breakpoint.mobile) {
            return "banner-title-tablet"
          } else {
            return "banner-title-desktop";
          }
        case "md":
          return "banner-title-tablet"
        case "sm":
        case "xs":
          return "banner-title-mobile";
      }
    },
    bannerHeadlineSubtitle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        if (this.$vuetify.breakpoint.mobile) {
            return "banner-subtitle-tablet"
          } else {
            return "banner-subtitle-desktop";
          }
        case "md":
          return "banner-subtitle-tablet"
        case "sm":
        case "xs":
          return "banner-subtitle-mobile";
      }
    },
    title() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "montserrat-extra-bold-purple-pizzazz-48px title-desktop";
        case "md":
          return "montserrat-extra-bold-purple-pizzazz-24px title-tablet";
        case "sm":
        case "xs":
          return "montserrat-extra-bold-purple-pizzazz-24px title-phone";
      }
    },
    subtitle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "material-themeheadlinesmall subtitle";
        case "md":
          return "material-themebodylarge subtitle";
        case "sm":
        case "xs":
          return "material-themebodylarge subtitle";
      }
    },
    benefits() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        if (this.$vuetify.breakpoint.mobile) {
            return "benefits-tablet"
          } else {
            return "benefits-desktop";
          }
        case "md":
          return "benefits-tablet"
        case "sm":
        case "xs":
          return "benefits-mobile";
      }
    },
    section5Class() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "section5-desktop";
        case "md":
          if (this.$vuetify.breakpoint.mobile) {
            return "section5-tablet"
          } else {
            return "section5-desktop";
          }
        case "sm":
          return "section5-tablet"
        case "xs":
          return "section5-mobile";
      }
    },
  },
  methods: {
    networkClass(index) {
      if (this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.mdAndDown) {
        return index % 2 === 0 ? 'network-desktop-even' : 'network-desktop-odd';
      } else {
        return "network-mobile";
      }
    },
  },
};
</script>

<style scoped>
.banner-container {
  width: 100%;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.banner {
  background-size: 100% 100%;
  justify-content: center;
  width: 1294px;
  position: relative;
  align-items: center;
}

.banner-desktop {
  height: 845px;
}

.banner-mobile {
  height: 760px;
}

.banner-title {
  color: var(--purple-pizzazz);
  font-family: var(--font-family-montserrat);
  font-weight: 800;
  letter-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.banner-title-desktop {
  font-size: 64px;
  line-height: 70.4px;
  margin-top: 106px;
  width: 100%;
}

.banner-title-tablet {
  font-size: 36px;
  line-height: 40px;
  margin-top: 80px;
  max-width: 442px;
}

.banner-title-mobile {
  font-size: 32px;
  line-height: 35px;
  margin-top: 80px;
  width: 328px;
  max-width: 100vw;
}

.banner-subtitle {
  color: var(--iron);
  font-family: var(--font-family-montserrat);
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.banner-subtitle-desktop {
  font-size: 32px;
  line-height: 34px;
  margin-top: 24px;
  width: 100%;
}

.banner-subtitle-tablet {
  font-size: 18px;
  line-height: 39x;
  margin-top: 14px;
  width: 380px;
}

.banner-subtitle-mobile {
  font-size: 16px;
  line-height: 34px;
  margin-top: 12px;
  width: 330px;
  max-width: 100vw;
}

.network-mobile {
  margin-left: auto;
  margin-right: auto;
}

.network-desktop-even {
  margin-left: auto;
}

.network-desktop-odd {
  margin-right: auto;
}

.paragraph {
  color: var(--iron);
  font-weight: 400;
  line-height: 32px;
  text-align: justify;
  padding-left: 24px;
  border-width: 0px;
  border-left-width: 4px;
  border-style: solid;
  border-color: var(--purple-pizzazz);
}

.section-container {
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.row {
  margin: 0;
}

.full-row {
  width: 100%;
}

.benefits-container {
  width: 100%;
  background-color: var(--mirage);
}

.benefits-desktop {
  padding: 40px 120px;
}

.benefits-tablet {
  padding: 40px 48px;
}

.benefits-phone {
  padding: 20px;
}

.benefit-container {
  gap: 52px;
  justify-content: center;
}

.benefit-icon {
  height: 52px;
  width: 52px;
  display: block;
  margin: auto;
}

.benefit-label {
  color: var(--purple-pizzazz);
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.section5-desktop {
  margin: 40px 60px;
}

.section5-desktop>>>img {
  max-width: 320px;
}

.section5-tablet {
  margin: 8px 0px;
}

.section5-tablet>>>img {
  max-width: 196px;
}

.section5-mobile {
  margin: 0;
}

.section5-mobile>>>img {
  max-width: 222px;
}

.section5-title {
  text-align: start;
  margin-top: 0px !important;
}

.section5-headline {
  color: white;
  width: 100%;
  text-align: justify;
  text-justify: inter-word;
}

.section5-paragraph {
  font-size: var(--font-size-l);
}
</style>
