<template>
  <page class="screen" title="Privacy Policy">
    <div class="privacy" v-html="data"></div>
  </page>
</template>

<script>
import Page from "./Page"

export default {
  name: "Policy",
  components: { Page },
  props: [
    "data"
  ],
};
</script>

<style scoped>
div.privacy>>>p {
  color: var(--iron);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  font-feature-settings: 'clig' off, 'liga' off;
  line-height: 24px;
  width: 100%;
  text-align: justify;
  text-justify: inter-word;
}

div.privacy>>>h1 {
  color: var(--purple-pizzazz);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  text-transform: uppercase;
  width: 100%;
  text-align: start;
  margin-top: 24px;
  margin-bottom: 24px;
}

div.privacy>>>a {
  color: var(--purple-pizzazz);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

div.privacy>>>span {
  color: var(--purple-pizzazz);
  font-weight: bold !important;
}

div.privacy>>>ul {
  list-style-type: disc;
}

div.privacy>>>ul>li {
  color: var(--iron);
}
</style>
